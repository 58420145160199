import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getTodayPrompt } from '../services/firebase';
import { PromptDisplay } from '../components/PromptDisplay';

const Screen = styled.div`
  height: 100vh; /* Fallback */
  height: 100dvh; /* Modern mobile browsers */
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 28px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 24px;
  }
`;

const LogoutButton = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing.xl};
  display: flex;
  justify-content: space-between;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.lg};
  }
`;

const Circle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  
  &:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 60px;
    height: 60px;
  }
`;

function HomeScreen() {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState('loading...');

  useEffect(() => {
    const loadPrompt = async () => {
      try {
        console.log('Fetching prompt...');
        const todayPrompt = await getTodayPrompt();
        console.log('Received prompt:', todayPrompt);
        setPrompt(todayPrompt);
      } catch (error) {
        console.error('Error loading prompt:', error);
      }
    };
    
    loadPrompt();
  }, []);

  return (
    <Screen>
      <Header>
        <Logo>zoodle</Logo>
        <LogoutButton>Logout</LogoutButton>
      </Header>
      
      <MainContent>
        <PromptDisplay prompt={prompt} />
      </MainContent>
      
      <CircleContainer>
        <Circle onClick={() => navigate('/draw')}>
          <FontAwesomeIcon icon={faPencil} size="lg" />
        </Circle>
        
        <Circle onClick={() => navigate(`/gallery?prompt=${prompt}`)}>
          <FontAwesomeIcon icon={faEye} size="lg" />
        </Circle>
      </CircleContainer>
    </Screen>
  );
}

export default HomeScreen; 
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Canvas } from '../components/drawing/Canvas';
import { uploadDoodle, getTodayPrompt } from '../services/firebase';
import { Header } from '../components/drawing/Header';
import { Toolbar } from '../components/drawing/Toolbar';

const Screen = styled.div`
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
`;

const DrawingArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; // Important for proper flex behavior
`;

const ToolbarSection = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.toolbarBg};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: ${({ theme }) => theme.spacing.xs};
  display: flex;
  justify-content: center;
`;

const CanvasSection = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.toolbarBg};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0; // Important for proper flex behavior
`;

const BottomSection = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const SubmitButton = styled.button`
  width: 100%;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.7 : 1};
  transition: transform 0.1s ease-in-out;

  &:active {
    transform: ${props => props.disabled ? 'none' : 'scale(0.98)'};
  }
`;

function DrawingScreen() {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [tool, setTool] = useState('pencil');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prompt, setPrompt] = useState('loading...');

  useEffect(() => {
    const loadPrompt = async () => {
      try {
        const todayPrompt = await getTodayPrompt();
        setPrompt(todayPrompt);
      } catch (error) {
        console.error('Error loading prompt:', error);
      }
    };
    loadPrompt();
  }, []);

  const handleSubmit = async () => {
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      const canvas = canvasRef.current;
      if (!canvas) throw new Error('Canvas not found');

      const canvasElement = canvas.canvas.drawing;
      const imageBlob = await new Promise(resolve => canvasElement.toBlob(resolve));
      
      const userId = 'test-user-1';  // Will update with auth
      await uploadDoodle(userId, imageBlob, prompt);
      
      navigate(`/gallery?prompt=${prompt}`);
    } catch (error) {
      console.error('Error uploading doodle:', error);
      alert('Failed to upload doodle. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Screen>
      <Header 
        prompt={prompt} 
        onBack={() => navigate('/')} 
      />
      
      <DrawingArea>
        <ToolbarSection>
          <Toolbar 
            activeTool={tool}
            onToolChange={setTool}
            onUndo={() => canvasRef.current?.undo()}
            onClear={() => canvasRef.current?.clear()}
          />
        </ToolbarSection>

        <CanvasSection className="canvas-container">
          <Canvas
            ref={canvasRef}
            tool={tool}
          />
        </CanvasSection>

        <BottomSection>
          <SubmitButton 
            onClick={handleSubmit} 
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Uploading...' : 'Submit Doodle'}
          </SubmitButton>
        </BottomSection>
      </DrawingArea>
    </Screen>
  );
}

export default DrawingScreen;
import { db, storage } from '../config/firebase';
import { 
  collection, 
  addDoc, 
  getDocs,
  query, 
  where, 
  orderBy, 
  limit,
  increment,
  doc,
  updateDoc,
  startAfter,
  getDoc,
  setDoc
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Get today's prompt
export const getTodayPrompt = async () => {
  const q = query(
    collection(db, 'prompts'),
    where('isActive', '==', true),
    limit(1)
  );
  
  const querySnapshot = await getDocs(q);
  const prompt = querySnapshot.docs[0]?.data();
  return prompt?.word || 'default';
};

// Upload doodle
export const uploadDoodle = async (userId, imageBlob, prompt) => {
  // Upload image to Storage
  const storageRef = ref(storage, `doodles/${userId}_${Date.now()}.png`);
  const snapshot = await uploadBytes(storageRef, imageBlob);
  const imageUrl = await getDownloadURL(snapshot.ref);

  // Save to Firestore
  return addDoc(collection(db, 'doodles'), {
    userId,
    imageUrl,
    prompt,
    loves: 0,
    laughs: 0,
    createdAt: new Date()
  });
};

// Get gallery doodles with pagination
export const getGalleryDoodles = async (page = 1, prompt) => {
  const pageSize = 20;
  try {
    console.log('Query starting with prompt:', prompt); // Debug log
    
    let q;
    if (prompt) {
      q = query(
        collection(db, 'doodles'),
        where('prompt', '==', prompt),  // Filter by today's prompt
        orderBy('loves', 'desc'),       // Sort by loves (highest first)
        limit(pageSize)
      );
      console.log('Filtering by prompt:', prompt, 'and ordering by loves'); // Debug log
    } else {
      q = query(
        collection(db, 'doodles'),
        orderBy('loves', 'desc'),
        limit(pageSize)
      );
      console.log('No prompt filter, only ordering by loves'); // Debug log
    }
    
    const querySnapshot = await getDocs(q);
    const doodles = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate()
    }));
    
    console.log('Found doodles:', doodles.length);
    console.log('Sample doodle prompts:', doodles.map(d => d.prompt)); // Debug log
    
    return doodles;
  } catch (error) {
    console.error('Error in getGalleryDoodles:', error);
    throw error;
  }
};

// Add reaction with optimistic update
export const addReaction = async (doodleId, reactionType) => {
  const doodleRef = doc(db, 'doodles', doodleId);
  
  try {
    await updateDoc(doodleRef, {
      [reactionType]: increment(1),
      lastUpdated: new Date()
    });
    
    return true;
  } catch (error) {
    console.error('Error adding reaction:', error);
    throw error;
  }
};

// Get user's doodles
export const getUserDoodles = async (userId) => {
  const q = query(
    collection(db, 'doodles'),
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate()
  }));
};

// Get top doodles for leaderboard
export const getLeaderboardDoodles = async () => {
  const q = query(
    collection(db, 'doodles'),
    orderBy('loves', 'desc'),
    limit(10)
  );
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate()
  }));
}; 
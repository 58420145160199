import styled from 'styled-components';

const StyledPrompt = styled.h2`
  font-size: ${props => props.size === 'large' ? '48px' : '24px'};
  color: ${({ theme }) => theme.colors.primary};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${props => props.size === 'large' ? '36px' : '20px'};
  }
  
  &::before {
    content: '#';
  }
`;

export const PromptDisplay = ({ prompt, size = 'large' }) => (
  <StyledPrompt size={size}>{prompt}</StyledPrompt>
); 
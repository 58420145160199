import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import CanvasDraw from 'react-canvas-draw';
import PropTypes from 'prop-types';

const CanvasWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
`;

export const Canvas = forwardRef(({ tool, ...props }, ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isReady, setIsReady] = useState(false);

  const getPencilConfig = () => {
    if (tool === 'eraser') {
      return {
        brushColor: '#ffffff',
        brushRadius: 8,
        lazyRadius: 0
      };
    }
    
    return {
      brushColor: 'rgba(0, 0, 0, 0.7)',
      brushRadius: 0.8,
      lazyRadius: 2,
      hideGrid: true,
      saveSaveData: true,
      immediateLoading: true,
    };
  };

  useEffect(() => {
    const updateDimensions = () => {
      const container = document.querySelector('.canvas-container');
      if (!container) return;

      const rect = container.getBoundingClientRect();
      const width = Math.min(rect.width - 32, 600);
      const height = rect.height - 32;
      
      setDimensions({ width, height });
      setIsReady(true);
    };

    updateDimensions();
    
    const resizeObserver = new ResizeObserver(updateDimensions);
    const container = document.querySelector('.canvas-container');
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const preventTouchScroll = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const canvas = document.querySelector('.canvas-draw');
    if (canvas) {
      canvas.addEventListener('touchmove', preventTouchScroll, { passive: false });
      canvas.addEventListener('touchend', preventTouchScroll, { passive: false });
    }
    
    return () => {
      if (canvas) {
        canvas.removeEventListener('touchmove', preventTouchScroll);
        canvas.removeEventListener('touchend', preventTouchScroll);
      }
    };
  }, []);

  if (!isReady) {
    return <CanvasWrapper />;
  }

  return (
    <CanvasWrapper>
      <CanvasDraw
        ref={ref}
        canvasWidth={dimensions.width}
        canvasHeight={dimensions.height}
        backgroundColor="#ffffff"
        style={{
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          borderRadius: '8px',
        }}
        hideInterface={true}
        catenaryColor="rgba(0,0,0,0)"
        {...getPencilConfig()}
        {...props}
      />
    </CanvasWrapper>
  );
});

Canvas.propTypes = {
  tool: PropTypes.oneOf(['pencil', 'eraser']).isRequired
};

Canvas.defaultProps = {
  tool: 'pencil'
}; 
import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faFaceLaugh, faArrowLeft, faArrowDown, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getGalleryDoodles, addReaction } from '../services/firebase';

const Screen = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  overflow: hidden;
`;

const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.sm};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
`;

const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};
  font-size: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.toolbarBg};
`;

const Tab = styled.button`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.md};
  border: none;
  background: none;
  cursor: pointer;
  font-size: clamp(14px, 4vw, 16px);
  color: ${props => props.active ? ({ theme }) => theme.colors.primary : '#666'};
  border-bottom: ${props => props.active ? `2px solid ${({ theme }) => theme.colors.primary}` : 'none'};
`;

const GalleryContent = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: ${({ theme }) => theme.spacing.sm};
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const DoodleCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
`;

const DoodleImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
  background: white;
`;

const ReactionBar = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing.sm};
  gap: ${({ theme }) => theme.spacing.xs};
`;

const ReactionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  border: none;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.toolbarBg};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const ModalContent = styled.div`
  background: white;
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
`;

const PullToRefresh = styled.div`
  height: ${props => props.visible ? '60px' : '0px'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.text};
  opacity: ${props => props.visible ? 1 : 0};
  transition: all 0.2s;
  background: ${({ theme }) => theme.colors.background};
  overflow: hidden;
`;

const RefreshIcon = styled(FontAwesomeIcon)`
  transition: transform 0.2s;
  transform: rotate(${props => props.rotation}deg);
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
`;

function GalleryScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const prompt = searchParams.get('prompt');
  const [activeTab, setActiveTab] = useState('gallery');
  const [selectedDoodle, setSelectedDoodle] = useState(null);
  const [doodles, setDoodles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pullStartY = useRef(0);
  const pulling = useRef(false);
  const pullDistance = useRef(0);
  const maxPullDistance = 150;

  // Initial load
  useEffect(() => {
    loadDoodles();
  }, []);

  // Pull to refresh handlers
  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    pullStartY.current = touch.clientY;
  };

  const handleTouchMove = (e) => {
    if (refreshing) return;
    
    const touch = e.touches[0];
    const pullMoveY = touch.clientY;
    pullDistance.current = Math.min(
      maxPullDistance, 
      Math.max(0, pullMoveY - pullStartY.current)
    );
    
    if (pullDistance.current > 0 && window.scrollY === 0) {
      pulling.current = true;
      e.preventDefault();
    }
  };

  const handleTouchEnd = async () => {
    if (pulling.current && !refreshing) {
      pulling.current = false;
      setRefreshing(true);
      await loadDoodles(true); // true means refresh
      setRefreshing(false);
    }
  };

  // Infinite scroll handler
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1000
      && !loading
      && hasMore
    ) {
      loadDoodles();
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const loadDoodles = async (refresh = false) => {
    if (loading && !refresh) return;
    
    try {
      setLoading(true);
      console.log('Loading doodles with prompt:', prompt);
      const newDoodles = await getGalleryDoodles(refresh ? 1 : page, prompt);
      console.log('Loaded doodles:', newDoodles.length);
      
      if (refresh) {
        setDoodles(newDoodles);
        setPage(1);
      } else {
        setDoodles(prev => {
          const existingIds = new Set(prev.map(d => d.id));
          const uniqueNewDoodles = newDoodles.filter(d => !existingIds.has(d.id));
          return [...prev, ...uniqueNewDoodles];
        });
        setPage(p => p + 1);
      }
      
      setHasMore(newDoodles.length > 0);
    } catch (error) {
      console.error('Error loading doodles:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReaction = async (doodleId, type) => {
    try {
      await addReaction(doodleId, type);
      // Update local state
      setDoodles(doodles.map(doodle => 
        doodle.id === doodleId 
          ? { ...doodle, [type]: (doodle[type] || 0) + 1 }
          : doodle
      ));
    } catch (error) {
      console.error('Error adding reaction:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'gallery':
        return (
          <GalleryGrid>
            {doodles.map((doodle) => (
              <DoodleCard key={`${doodle.id}-${doodle.createdAt}`}>
                <DoodleImage 
                  src={doodle.imageUrl} 
                  alt="Doodle" 
                  onClick={() => setSelectedDoodle(doodle)}
                />
                <ReactionBar>
                  <ReactionButton onClick={() => handleReaction(doodle.id, 'loves')}>
                    ❤️ {doodle.loves || 0}
                  </ReactionButton>
                  <ReactionButton onClick={() => handleReaction(doodle.id, 'laughs')}>
                    😄 {doodle.laughs || 0}
                  </ReactionButton>
                </ReactionBar>
              </DoodleCard>
            ))}
          </GalleryGrid>
        );
      case 'leaderboard':
        return (
          <div style={{ padding: '20px' }}>
            <h2>Most Loved Doodles</h2>
            {/* Add leaderboard content */}
          </div>
        );
      case 'mydoodles':
        return (
          <div style={{ padding: '20px' }}>
            <h2>My Doodles</h2>
            {/* Add personal doodles content */}
          </div>
        );
      default:
        return null;
    }
  };

  const getRotation = () => {
    if (refreshing) return 360;
    return (pullDistance.current / maxPullDistance) * 180;
  };

  return (
    <Screen
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Header>
        <BackButton onClick={() => navigate('/')}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </BackButton>
        <Title>
          {prompt ? `#${prompt}` : 'All Doodles'}
        </Title>
      </Header>

      <TabContainer>
        <Tab 
          active={activeTab === 'gallery'} 
          onClick={() => setActiveTab('gallery')}
        >
          Gallery
        </Tab>
        <Tab 
          active={activeTab === 'leaderboard'} 
          onClick={() => setActiveTab('leaderboard')}
        >
          Leaderboard
        </Tab>
        <Tab 
          active={activeTab === 'mydoodles'} 
          onClick={() => setActiveTab('mydoodles')}
        >
          My Doodles
        </Tab>
      </TabContainer>

      <GalleryContent>
        <PullToRefresh visible={pulling.current || refreshing}>
          <RefreshIcon 
            icon={refreshing ? faRotate : faArrowDown} 
            rotation={getRotation()}
            spin={refreshing}
          />
          {refreshing ? 'Refreshing...' : 'Pull to refresh'}
        </PullToRefresh>

        {renderContent()}
        
        {loading && <div style={{ textAlign: 'center', padding: '20px' }}>Loading more doodles...</div>}
      </GalleryContent>

      <Modal show={!!selectedDoodle} onClick={() => setSelectedDoodle(null)}>
        <ModalContent onClick={e => e.stopPropagation()}>
          {selectedDoodle && (
            <>
              <DoodleImage 
                src={selectedDoodle.imageUrl} 
                alt="Doodle" 
                style={{ height: 'auto', maxHeight: '70vh' }} 
              />
              <ReactionBar>
                <ReactionButton onClick={() => handleReaction(selectedDoodle.id, 'loves')}>
                  ❤️ {selectedDoodle.loves || 0}
                </ReactionButton>
                <ReactionButton onClick={() => handleReaction(selectedDoodle.id, 'laughs')}>
                  😄 {selectedDoodle.laughs || 0}
                </ReactionButton>
              </ReactionBar>
            </>
          )}
        </ModalContent>
      </Modal>
    </Screen>
  );
}

export default GalleryScreen; 
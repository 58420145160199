export const theme = {
  colors: {
    primary: '#007AFF',
    primaryHover: '#0056b3',
    background: '#ffffff',
    text: '#333333',
    toolbarBg: '#f5f5f5',
  },
  spacing: {
    xs: 'clamp(4px, 1vw, 8px)',
    sm: 'clamp(8px, 2vw, 16px)',
    md: 'clamp(16px, 3vw, 24px)',
    lg: 'clamp(24px, 4vw, 32px)',
    xl: 'clamp(32px, 5vw, 48px)',
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
  }
}; 
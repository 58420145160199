import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDiHRZdHBQ_d088h3LvLKYz18wR5JLJC9w",
    authDomain: "zoodle-20507.firebaseapp.com",
    projectId: "zoodle-20507",
    storageBucket: "zoodle-20507.firebasestorage.app",
    messagingSenderId: "186931560141",
    appId: "1:186931560141:web:ef34c5b4424562117cb384",
    measurementId: "G-XRSTMDJFFL"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); 
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEraser, faRotateLeft, faUndo } from '@fortawesome/free-solid-svg-icons';

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.toolbarBg};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: ${({ theme }) => theme.spacing.sm};
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const Tool = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary : theme.colors.background};
  color: ${({ active, theme }) => 
    active ? theme.colors.background : theme.colors.text};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 40px;
    height: 40px;
  }
  
  &:active {
    transform: scale(0.95);
  }
`;

export const Toolbar = ({ activeTool, onToolChange, onUndo, onClear }) => (
  <ToolbarWrapper>
    <Tool 
      active={activeTool === 'pencil'} 
      onClick={() => onToolChange('pencil')}
    >
      <FontAwesomeIcon icon={faPencil} />
    </Tool>
    <Tool 
      active={activeTool === 'eraser'} 
      onClick={() => onToolChange('eraser')}
    >
      <FontAwesomeIcon icon={faEraser} />
    </Tool>
    <Tool onClick={onUndo}>
      <FontAwesomeIcon icon={faUndo} />
    </Tool>
    <Tool onClick={onClear}>
      <FontAwesomeIcon icon={faRotateLeft} />
    </Tool>
  </ToolbarWrapper>
); 
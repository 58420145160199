import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PromptDisplay } from '../PromptDisplay';

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.md};
  position: relative;
  min-height: 48px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.sm};
    min-height: 56px;
  }
`;

const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
  padding: ${({ theme }) => theme.spacing.xs};
  z-index: 1;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 20px;
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const Title = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

export const Header = ({ prompt, onBack }) => (
  <HeaderWrapper>
    <BackButton onClick={onBack}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </BackButton>
    <Title>
      <PromptDisplay prompt={prompt} size="small" />
    </Title>
  </HeaderWrapper>
); 
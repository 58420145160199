import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { GlobalStyles } from './styles/globalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import DrawingScreen from './screens/DrawingScreen';
import GalleryScreen from './screens/GalleryScreen';
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/draw" element={<DrawingScreen />} />
          <Route path="/gallery" element={<GalleryScreen />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
